<template>
  <div :key="$route.params.slug" class="wrapper wrapper-nopadding page">
    <article v-if="page">
      <div class="wrapper wrapper-mobilepadding">
        <h1>{{ page.title }}</h1>
      </div>

      <div class="wrapper wrapper-mobilepadding">
        <vue-markdown toc class="markdown">
          {{ page.content }}
        </vue-markdown>
      </div>
    </article>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueMarkdown from 'vue-markdown';

export default {
  name: 'AppsPage',
  components: {
    VueMarkdown
  },
  computed: {
    ...mapGetters([
      'legalPage'
    ]),
    page: function () {
      return this.legalPage(this.$route.params.slug);
    }
  },
  beforeCreate() {
    this.$store.dispatch('loadLegalPage', this.$route.params.slug);
  },
  watch: {
    $route(to) {
      this.$store.dispatch('loadLegalPage', to.params.slug);
    }
  }
}
</script>